import { lazy } from "react";
import { Navigate } from "react-router-dom";

import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";

import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";

import materialRoutes from "app/views/material-kit/MaterialRoutes";

// SESSION PAGES
const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));
const JwtLogin = Loadable(lazy(() => import("app/views/sessions/JwtLogin")));
const JwtRegister = Loadable(lazy(() => import("app/views/sessions/JwtRegister")));
const ForgotPassword = Loadable(lazy(() => import("app/views/sessions/ForgotPassword")));
// E-CHART PAGE
const AppEchart = Loadable(lazy(() => import("app/views/charts/echarts/AppEchart")));
// DASHBOARD PAGE
const Analytics = Loadable(lazy(() => import("app/views/dashboard/Analytics")));
const Dashboard = Loadable(lazy(() => import("app/views/dashboard/Dashboard")));
const Pairs = Loadable(lazy(() => import("app/views/dashboard/Pairs")));
const Profile = Loadable(lazy(() => import("app/views/dashboard/Profile")));
const Settings = Loadable(lazy(() => import("app/views/dashboard/settings/Settings")));

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,
      // dashboard route
      { path: "/dashboard", element: <Dashboard key={Math.random()} />, auth: authRoles.admin },
      { path: "/pairs", element: <Pairs />, auth: authRoles.admin },
      { path: "/pairs/*", element: <Dashboard key={Math.random()} />, auth: authRoles.admin },
      // e-chart route
      { path: "/charts/echarts", element: <AppEchart />, auth: authRoles.editor },
      { path: "/profile", element: <Profile />, auth: authRoles.admin },
      { path: "/settings", element: <Settings />, auth: authRoles.admin },
    ]
  },

  // session pages route
  { path: "/404", element: <NotFound /> },
  { path: "/login", element: <JwtLogin /> },
  { path: "/register", element: <JwtRegister /> },
  { path: "/forgot-password", element: <ForgotPassword /> },

  { path: "/", element: <Navigate to="dashboard" /> },
  { path: "*", element: <NotFound /> }
];

export default routes;
