import { UPDATE_SETTINGS } from '../actions/settingsActions';

const initialState = {
    twoStepVerification: [
        { enabled: false, confirmationSecret: "", confirmationCode: "", confirmPassword: ""}
    ],
    referredUsers: [
        { orderId: '678935899', user: 'Marcus Harris', date: 'Nov 12, 2021', bonus: '15%', profit: '$1200' },
        { orderId: '678935900', user: 'Robert Smith', date: 'Nov 14, 2021', bonus: '53%', profit: '$2400' },
    ],
    recentDevices: [
        { browser: 'Chrome on Windows', device: 'Dell XPS 12', location: 'New York, USA', status: 'current', activity: 'Now' },
        { browser: 'Mozilla Firefox', device: 'Acer Aspire 300', location: 'New York, USA', status: '', activity: '15 June 2020' },
        { browser: 'Safari Browser', device: 'Macbook Pro 2020', location: 'London, UK', status: '', activity: '05 October 2020' },
        { browser: 'Apple Browser', device: 'iPhone 13 Pro Max', location: 'Manchester, UK', status: '', activity: '05 October 2020' },
    ],
    password: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    },
    notifications: [
        { type: 'New for you', email: true, browser: false, app: false },
        { type: 'Account activity', email: true, browser: true, app: true },
        { type: 'A new browser used to sign in', email: true, browser: true, app: true },
        { type: 'A new device is linked', email: false, browser: true, app: false },
        { type: 'A new device connected', email: true, browser: false, app: false },
    ],
    generalSettings: {
        language: 'English',
        timeZone: '23.UTC+00:00',
        includeNewFeatures: false,
        allowProfileView: false,
    },
    apiKeys: {
        keys: [
            { label: 'none set', key: 'ftft456765gjkkjhi83093985', created: 'Nov 12, 2021', status: 'Active' },
            { label: 'Navitare', key: 'jk076590yggjhh324vd33', created: 'Nov 14, 2021', status: 'Active' },
            { label: 'Docs API Key', key: 'ftft456765gjkkjhi83093985', created: 'Nov 14, 2021', status: 'Inactive' },
            { label: 'Remore Interface', key: 'jk076590yggjhh324vd33', created: 'Nov 15, 2021', status: 'Active' },
        ],
        apiCallHistory: [
            { location: 'USA(5)', status: 'Ok', device: 'Chrome - Windows', ip: '236.125.56.78', time: '2 mins ago' },
            { location: 'United Kingdom(10)', status: 'Ok', device: 'Safari - Mac OS', ip: '236.125.56.79', time: '4 mins ago' },
            { location: 'Norway(8)', status: 'Error', device: 'Firefox - Windows', ip: '236.125.56.74', time: '10 mins ago' },
        ],
        disableAllKeys: false,
        timeRange: '1 Hour',
    },
    basicInformation: {
        firstName: 'Pixy',
        lastName: 'Krovaszky',
        email: 'uilib@gmail.com',
        phone: '+443322221111',
        organization: 'UiLib',
        department: 'Develop',
        country: 'United States',
        state: 'New York',
        address: 'Corverview, Michigan',
        zipCode: '4336',
        registrationDate: 'March 17', // read-only
    },
    billing: {
        paymentMethods: [
            { card: 'Paypal **** 1679', name: 'Marcus Morris', expiry: '09/24/2022' },
        ],
        billingHistory: [
            { orderId: '202745788', amount: '$890', date: 'Nov 12, 2021', format: 'PDF' },
            { orderId: '202745923', amount: '$420', date: 'Nov 10, 2021', format: 'DOC' },
        ],
    },
    connectedAccounts: [
        { platform: 'Google', connected: true },
        { platform: 'Facebook', connected: false },
        { platform: 'Twitter', connected: true },
    ],
    deleteAccount: [
        { confirmDelete: false },
    ]
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SETTINGS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default settingsReducer;